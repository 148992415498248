
.card {
    margin: 10px 12.5% 10px 12.5%;
    padding: 10px;
    max-width: 75%;
}

.image-card {
    display: flex;
    flex-direction: row;
    align-items: center;
}

img {
    max-width: 100%;
}

.image-container {
    max-width: 80px;
    margin-right: 10px;
}

.interest-item-lv1 {
    display: flex;
    justify-content: flex-start;
}

.interest-genre {
    min-width: 80px;
}

.education-type {
    font-style: italic;
}